import { Heading, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginConfirm } from '../../Services/AuthService';
import { setTokenInLocalStorage } from '../../_helpers/helpers';

export default function SSOLogin() {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const params  = useSearchParams()
    const searchParams = window.location.search.split("=")[1];
    useEffect(() => {
        if (!!searchParams?.length) {

            let data = {
                data: {
                    data: JSON.parse(atob(searchParams))
                }
            }
            loginConfirm(undefined, toast,data,  dispatch, navigate)
            
        }
    }, [])
    return (
        <>
            <Heading>Loading...</Heading>
        </>
    )
}
