
import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Box, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';

const Sinch = ({formikProps, setProviderData }) => {
  const { handleChange, errors, touched } = useFormikContext();

  return (
    <Box p={4} borderWidth={1} borderRadius="lg" boxShadow="lg">
      <FormControl id="account" mb={4} isInvalid={errors.account && touched.account}>
        <FormLabel>Account</FormLabel>
        <Field name="account" as={Input} placeholder="Account" onChange={(e) => {
          handleChange(e);
          setProviderData((prev) => ({ ...prev, account: e.target.value }));
        }} />
        <FormErrorMessage>{errors.account}</FormErrorMessage>
      </FormControl>

      <FormControl id="auth" mb={4} isInvalid={errors.auth && touched.auth}>
        <FormLabel>Auth</FormLabel>
        <Field name="auth" as={Input} placeholder="auth" onChange={(e) => {
          handleChange(e);
          setProviderData((prev) => ({ ...prev, auth: e.target.value }));
        }} />
        <FormErrorMessage>{errors.account}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default Sinch;

