import React, { useState, useEffect } from 'react'
import { Icon, Tbody, Td, Tr, Box, Flex, Text, Badge, Menu, MenuButton, MenuList, MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
import CampaignWisePerformance from './CampaignWisePerformance'
import { BsThreeDotsVertical } from "react-icons/bs";
import { channels } from '../../../_helpers/data_helper';
import moment from 'moment';
import Engagement from './Engagement';
import { useNavigate } from 'react-router-dom';
import CampaignDetailsModal from './CampaignDetailsModal';
import { deleteCampaign, publishCampaign } from '../../../Services/api';
import store from '../../../store/store';
import * as _ from 'lodash'
function CampaignTable({campaignDataRaw, campaignData, menuList, tableRef, broadcastHeaders,metrics ,campaignQuery}) {

    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const {onOpen, isOpen, onClose} = useDisclosure();
    const orgToken = store.getState().auth.selectedOrg.token;
    const toast = useToast();
    useEffect(() => {
        const handleScroll = () => {
            if (openMenuIndex !== null) {
                setOpenMenuIndex(null);
            }
        };
        const tableElement = document.querySelector('.css-1km9ei0')
        if (tableElement) {
            tableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableElement) {
                tableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [openMenuIndex]);

        const [details,setDetails] = useState({
            transactionCampaignSent:0,
            transactionCampaignNotSent:0,
            campaignSentToCustomers:0,
            campaignNotSentToCustomer:0,
            transactionCampaignSentPercentage: 0,
            transactionCampaignNotSentPercentage: 0
        });;

    const handleMenuOpen = (index) => {
        setOpenMenuIndex(index);
    };
    const handleMenuClose = () => {
        setOpenMenuIndex(null);
    };
    const navigate = useNavigate()
    const actionHandler = {
        delete: async function (data) {
            let resp = await deleteCampaign(data[0].uuid)
            if (resp.status === 200) {
                campaignQuery.refetch()
                toast(
                    {title: 'Campaign deleted successfully.',  status: 'success'}
                    )
            }
        },
        edit: function (data) {
            navigate('/campaign/' + data.uuid)
        },
        publish: function (data) {

            data = data[0]
            let scheduleData = {
                repeatAfterDays:data.campaignData.repeatAfterDays,
                repeatStopDateTime:data.campaignData.repeatStopDateTime,
                scheduleAt:data.campaignData.scheduleAt,
                scheduleStatus:data.campaignData.scheduleStatus
                }

            publishCampaign(orgToken,data.broadcastId,scheduleData,data.scheduleStatus)
        },
        pause: function (data) {
            data = data[0]
            let scheduleData = {
                repeatAfterDays:data.campaignData.repeatAfterDays,
                repeatStopDateTime:data.campaignData.repeatStopDateTime,
                scheduleAt:data.campaignData.scheduleAt,
                scheduleStatus:data.campaignData.scheduleStatus
                }

            publishCampaign(orgToken,data.broadcastId,scheduleData,"pause")
        },
        resume: function (data) {
            data = data[0]
            let scheduleData = {
                repeatAfterDays:data.campaignData.repeatAfterDays,
                repeatStopDateTime:data.campaignData.repeatStopDateTime,
                scheduleAt:data.campaignData.scheduleAt,
                scheduleStatus:data.campaignData.scheduleStatus
                }

            publishCampaign(orgToken,data.broadcastId,scheduleData,"resume")
        },
        downloadOrders: function (data) {
            console.log("download orders", data)
        },
        downloadCampaignReportNew: ((url) => {
            window.open(url)
        })
    }
    const badgeColor = {
        ERROR: 'red',
        COMPLETED: 'green',
        DRAFT: 'yellow',
        QUEUED : 'cyan'
    }

    return (
        <Tbody overflowY={'auto'} ref={tableRef} >
            {
                !!campaignData?.length && campaignData?.map((dt, i) => (
                    <Tr>
                        <Td w={'25%'} fontSize={'0.8vw'} verticalAlign={'middle'}>
                            <Flex gap={3}>
                                {<Icon as={channels[dt.channel]?.icon} boxSize={5}></Icon>}
                                <Box>
                                    <Text>{campaignDataRaw[i]['campaignData']?.broadcast=="CONTROL_GROUP" && "C_G " + dt.name || campaignDataRaw[i]?.campaignName }
                                    {
!Object.keys(broadcastHeaders).includes("All")&&
                                    <Badge colorScheme={badgeColor[dt.status]} marginLeft={'10px'} fontWeight={'400'}>
                                    {dt.status}
                                </Badge>
                                    }
                                    </Text>
                                    <Text color={'light'}>{moment(dt.createdDate).format('LL')} {moment(dt.createdDate).format('LT')}</Text>
                                    {

                                        <Text textDecoration={'underline'} onClick={()=>{
                                            setDetails(dt)
                                            onOpen()
                                        }} cursor={'pointer'} >View Details </Text>
                                    }
                                </Box>
                            </Flex>
                        </Td>
                        {Object.keys(broadcastHeaders).includes("All") &&
                            <Td w={'6%'} fontSize={'0.8vw'}>
                                <Badge colorScheme={badgeColor[dt.status]} fontWeight={'400'}>
                                    {dt.status}
                                </Badge>
                            </Td>}
                        {!Object.keys(broadcastHeaders).includes("All") &&
                            <Td w={'6%'} fontSize={'0.8vw'}>
                                <Text fontWeight={'400'}>
                                    {dt.total_reach}
                                </Text>
                            </Td>}
                        {
                            Object.keys(broadcastHeaders).includes("All") &&
                            <Td w="7%" fontSize={'0.8vw'}>
                                <Box>
                                    <Text>{moment(dt.sentAt).format('LL')} {moment(dt.sentAt).format('LT')} </Text>
                                    <Text>{moment(dt.createdDate).format('LT')}</Text>
                                </Box>
                            </Td>}

                        {!Object.keys(broadcastHeaders).includes("All") && <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{ dt.sent }</Text>
                                {/* <Text>{moment(dt.createdDate).format('LT')}</Text> */}
                            </Box>
                        </Td>}
                        {!Object.keys(broadcastHeaders).includes("All") && <Td w={'6%'} fontSize={'0.8vw'}>
                            <Text fontWeight={'400'}>
                                {dt.delivered}
                            </Text>
                        </Td>}
                        {!Object.keys(broadcastHeaders).includes("All") && <Td w={'6%'} fontSize={'0.8vw'}>
                            <Text fontWeight={'400'}>
                                {dt.opened}
                            </Text>
                        </Td>}
                        {!Object.keys(broadcastHeaders).includes("All") && <Td w={'6%'} fontSize={'0.8vw'}>
                            <Text fontWeight={'400'}>
                                {dt.failed}
                            </Text>
                        </Td>}
                        {!Object.keys(broadcastHeaders).includes("All") && <Td w={'6%'} fontSize={'0.8vw'}>
                            <Text fontWeight={'400'}>
                                {dt.clicked}
                            </Text>
                        </Td>}
                        {
                            Object.keys(broadcastHeaders).includes("All") &&
                            <Td w={'60%'} px={0} >
                                <CampaignWisePerformance data={dt.performance}></CampaignWisePerformance>
                            </Td>
                        }

                        {
                            Object.keys(broadcastHeaders).includes("All") &&
                            <Td w={'15%'} fontSize={'0.8vw'}>
                                <Engagement data={dt.engagement}></Engagement>
                            </Td>
                        }

                        {Object.keys(broadcastHeaders).includes("All") && <Td w={'7%'} fontSize={'0.8vw'}>
                            {dt.revenue}
                        </Td>}
                        <Td w={'10%'}>
                            <Menu isOpen={openMenuIndex === i} onClose={handleMenuClose}>
                                <MenuButton onClick={() => handleMenuOpen(i)}>
                                    <Icon as={BsThreeDotsVertical}></Icon>
                                </MenuButton>
                                <MenuList dataValue={i} ref={menuList} >
                                    {
                                        dt.actions.map(ac => (
                                            <MenuItem key={ac.value} onClick={() => actionHandler[ac.value](_.filter(campaignDataRaw,{ uuid: dt.uuid}) )}>{ac.label}</MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </Menu>

                        </Td>
                    </Tr>
                ))
            }
            {/* <Tr>
                <Td w={'25%'}>First Campaign</Td>
                <Td w={'6%'}>End of Season Sale</Td>
                <Td w="7%">{JSON.stringify(new Date())}</Td>
                <Td w={'30%'}>
                    <CampaignWisePerformance></CampaignWisePerformance>
                </Td>
                <Td w={'15%'}>
                    Engagement
                </Td>
                <Td w={'7%'}>
                    10
                </Td>
                <Td w={'10%'}>
                    Action
                </Td>
            </Tr> */}
        <CampaignDetailsModal rawData={campaignDataRaw} isOpen={isOpen} onClose={onClose} data={details} />
        </Tbody>
    )
}

export default CampaignTable
