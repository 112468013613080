import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Grid,
  Progress,
  Flex,
  Badge,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/react";
import MobilePreview from "./MobilePreview";
import axiosInstance from "../../../Services/axiosInstance";

function CampaignDetailsModal({ isOpen, onClose, data, rawData }) {
  const statusColors = {
    RUNNING: "#08830D",
    QUEUED: "cyan",
    RECURRING: "#08830D",
    SCHEDULED: "#836707",
    PROCESSING: "#836707",
    COMPLETED: "#08830D",
    ERROR: "#FE1B1B",
    WAITING: "#836707",
    STOPPED: "#FE1B1B",
    DRAFT: "#836707",
    ARCHIVED: "#FE1B1B",
  };

  const [filteredData, setFilteredData] = useState({});
  const getWhatsappTemplateDetails = (uuid) => {
    const url = `/whatsapp/template/${uuid}`
    axiosInstance.get(url).then((response) =>{
      // console.log(response.data.templates);
      setFilteredData(response?.data?.templates)
    })

  }
  const getSmsTemplateDetails = (uuid) => {
    let url = "/sms/template/" + uuid;
    axiosInstance.get(url).then((response) =>{
      setFilteredData(response.data)
    })
  }
  useEffect(() => {
    if(data.channel == 'whatsapp'){
      getWhatsappTemplateDetails(data.template)
    }
    if(data.channel == 'sms'){
      getSmsTemplateDetails(data.template)
    }
    // ssetFilteredData(matchedData || {});
  }, [data, rawData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader fontSize="2xl" fontWeight="bold">
          Campaign Group Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>

          <VStack spacing={6} align="stretch">
            {/* Control Group Statistics */}
            {data.controlGroupMetrics && (
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  Control Group Statistics
                </Text>
                <Grid
                  templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                  gap={6}
                  >
                  {[
                    {
                      label: "Transaction Campaign Sent",
                      value:
                      data?.controlGroupMetrics?.transactionCampaignSent || 0,
                    },
                    {
                      label: "Transaction Campaign Not Sent",
                      value:
                      data?.controlGroupMetrics?.transactionCampaignNotSent || 0,
                    },
                    {
                      label: "Campaign Sent to Customers",
                      value:
                      data?.controlGroupMetrics?.campaignSentToCustomers || 0,
                    },
                    {
                      label: "Campaign Not Sent to Customers",
                      value:
                      data?.controlGroupMetrics?.campaignNotSentToCustomer || 0,
                    },
                  ].map((metric, index) => (
                    <Box
                    key={index}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                    bg="gray.50"
                    >
                      <Text fontWeight="semibold" color="gray.600">
                        {metric?.label}
                      </Text>
                      <Text fontSize="xl" fontWeight="bold">
                        {metric?.value}
                      </Text>
                    </Box>
                  ))}
                </Grid>
                <Box mt={8}>
                  <Text fontSize="lg" fontWeight="bold" mb={2}>
                    Sent vs. Not Sent
                  </Text>
                  <HStack justify="space-between">
                    <Text>
                      Sent:{" "}
                      {data?.controlGroupMetrics?.transactionCampaignSentPercentage ||
                        0}
                      %
                    </Text>
                    <Text>
                      Not Sent:{" "}
                      {data?.controlGroupMetrics?.transactionCampaignNotSentPercentage ||
                        0}
                      %
                    </Text>
                  </HStack>
                  <Progress
                    colorScheme="green"
                    value={
                      data?.controlGroupMetrics?.transactionCampaignSentPercentage ||
                      0
                    }
                    mt={2}
                    />
                </Box>
              </Box>
            )}

            {/* Campaign Statistics */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Campaign Statistics
              </Text>
              <Grid templateColumns="1fr 2fr" rowGap={4} columnGap={6}>
                {[
                  { label: "Name", value: data.name },
                  {
                    label: "Channel",
                    value: (
                      <HStack>
                        <Text>{data.channel}</Text>
                        <img
                          src={`${process.env.REACT_APP_API_ENDPOINT}/assets/img/broadcast-v2/create/${data.channel}.svg`}
                          alt="channel icon"
                          style={{ height: 15, width: 15 }}
                          />
                      </HStack>
                    ),
                  },
                  {
                    label: "Status",
                    value: (
                      <Badge
                      colorScheme={statusColors[data.status]}
                      px={3}
                      py={1}
                      >
                        {data?.status}
                      </Badge>
                    ),
                  },
                  data.status === "SCHEDULED" && {
                    label: "Scheduled At",
                    value: new Date(data.scheduleAt).toLocaleString(),
                  },
                  { label: "Total Sent", value: data?.messagesSentCount || 0 },
                  { label: "Total Suppressed", value: data?.suppressedCount || 0 },
                  {
                    label: "Total Frequency Capped",
                    value: data.freqCappedCount || 0,
                  },
                  { label: "Total Duplicates", value: data?.duplicatesCount || 0 },
                  data.actionId && {
                    label: "Tracking ID",
                    value: data?.actionId,
                  },
                ]
                .filter(Boolean)
                .map((stat, index) => (
                  <React.Fragment key={index}>
                      <Text fontWeight="semibold">{stat.label}</Text>
                      <Text>{stat.value}</Text>
                    </React.Fragment>
                  ))}
              </Grid>
            </Box>

           
            {(data.channel === "email" || data.channel === "onsitepush") && (
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Template Preview
                </Text>
                <a
                  href={`${process.env.REACT_APP_API_ENDPOINT}/broadcast/v2/preview/${data.template}/${data.channel}`}
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                    marginBottom: 8,
                  }}
                  rel="noopener noreferrer"
                  >
                  Click here to see template preview
                </a>
              </Box>
            )}

            
          </VStack>

          {(data.channel === "sms" || data.channel === "whatsapp") && (
            <MobilePreview
            message={filteredData?.message}
            channel={data?.channel}
            buttons={filteredData?.buttons}
            attachment={filteredData?.attachments}
            />
          )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CampaignDetailsModal;
