import React, { useEffect, useState } from 'react'
// import AudienceSelection from "../Campaigns/components/AudienceSelection"
import { useQuery, useQueryClient } from 'react-query';
import { getPredefinedSegmentCount, getSegmentCounts, getSegmentList, getTotalSubscribers, getUploadedFiles, getUploadedFilesAllBanks, updateSegment, getSegmentListAllBanks, getSegmentCountsAllBanks } from '../../Services/api';
import AudienceSelectionTG from './AudienceSelectionTG'
import { Box, Text, VStack } from '@chakra-ui/react'
import { prepareSegmentList } from '../../_helpers/data_helper';
import store from '../../store/store';
import { calcLength } from 'framer-motion';

export default function AudieceSelection({ formik, targetGroupData, setTargetGroupData, setReachForOrgs , reachForOrgs}) {
  const [segmentsList, setSegmentsList] = useState({})
  const orgList = store.getState().org.orgList
  const [isSegmentModalOpen, setIsSegmentModalOpen] = useState(false)
  const uploadedFilesQuery = useQuery('uploadedFiles', {
    queryFn: async () => {
      let res = await getUploadedFilesAllBanks(formik.values.orgList, formik.values.orgList, formik.values.selectedChannel);
      console.log("res", res);
      return res.data;
    },
    enabled: true,
    staleTime: 0
  })
  const segmentQuery = useQuery('segments', {
    queryFn: async () => {
      let custom = await getSegmentListAllBanks('custom', formik.values.orgList)
      return custom.data.data
    },
    enabled: true,
    staleTime: 0
  })
  const getOrgName = (token) => {
    
    let orgName = orgList.filter((e) => e.token == token)
    return orgName[0]?.name || ''
  }
  let segmentCount = {
    predefined: [],
    custom: [],
    rfm: []
};
  const segmentCountQuery = useQuery('segmentCounts', {
    queryFn: async () => {
      
        let segmentCounts = await getSegmentCountsAllBanks(formik.values.orgList)      
        if (segmentCounts.status == 200) {
            segmentCount['custom'] = segmentCounts.data
        }
        return segmentCounts.data
    },
    enabled: true,
    staleTime: 0
})
  const openCreateSegmentModal = () => {
    setIsSegmentModalOpen(true)
  }

  return (
    <>
    <Text textDecoration={'underline'}  cursor={'pointer'} textAlign={'end'}  onClick = {openCreateSegmentModal} >
      Create Segment +
    </Text>
      {
        uploadedFilesQuery?.data?.length > 0 && uploadedFilesQuery.data.map((e, i) => (
          <Box bg={'#fff'} padding={'1rem'}  margin={"10px"} >
            <Text fontSize={'1vw'}  w={"100%"} >
              Select Audience For <Box as ={'span'} fontWeight={'500'} > {getOrgName(e.org_token)}</Box>
            </Text>
            <AudienceSelectionTG isSegmentModalOpen = {isSegmentModalOpen} setIsSegmentModalOpen={setIsSegmentModalOpen} reachForOrgs={reachForOrgs} setReachForOrgs={setReachForOrgs} segmentCountQuery={segmentCountQuery} segmentQuery={segmentQuery} setSegmentsList={setSegmentsList} uploadedFilesQuery={e} targetGroupData={targetGroupData} setTargetGroupData={setTargetGroupData} selectedChannel={"email"} index={i} orgId={e.org_token} orgToken={e}  formik={formik} showSliders={false} ></AudienceSelectionTG>
          </Box>
        ))
      }
    </>
  )
}
