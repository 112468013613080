import React, { useState, useEffect } from 'react';
import { transctionDataBycustomers } from '../../Services/api';
import Chart from 'react-apexcharts';
import { Text, Grid, GridItem, Box, Heading, VStack, HStack, Divider, Icon } from '@chakra-ui/react';
import { MdCurrencyRupee, MdTrendingUp } from 'react-icons/md';

const ControlGroup = () => {
    const [transactionalData, setTransactionalData] = useState(null);
    
    const fetchTransactionalData = async () => {
        try {
            const response = await transctionDataBycustomers();
            setTransactionalData(response?.data);
        } catch (error) {
            console.error("Error while fetching transaction data:", error);
        }
    };

    useEffect(() => {
        fetchTransactionalData();
    }, []);

    const chartData = transactionalData ? {
        series: [{
            name: 'Transactions',
            data: [
                transactionalData?.transactionCustomer,
                transactionalData?.transactionExclusionCustomer,
            ]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            xaxis: {
                categories: ['Customer Transactions', 'Exclusion Transactions'],
            },
            title: {
                text: 'Revenue Overview',
                align: 'center',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: true
            },
            colors: ['#1F80E8', '#E83E8C'],
        }
    } : { series: [], options: {} };

    const percentageCustomer = transactionalData ? transactionalData.transactionPercentageCustomer : 0;
    const percentageExclusion = transactionalData ? transactionalData.transactionPercentageExclusionCustomer : 0;
    const totalCustomerCount = transactionalData ? transactionalData.totalCustomerCount : 0;
    const totalExcludedCustomer = transactionalData ? transactionalData.totalExcludedCustomer : 0;

    return (
        <VStack spacing={5} align="stretch">
            <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={6}>
                <GridItem>
                    <Box
                        bg="white"
                        borderRadius="lg"
                        boxShadow="lg"
                        p={6}
                        maxH="450px"
                        minH="250px"
                    >
                        <HStack spacing={3} mb={4}>
                            {/*<Icon as={MdAttachMoney} boxSize={6} color="#1F80E8" />*/}
                            <Icon as={MdCurrencyRupee} boxSize={6} color="#1F80E8" />
                            <Heading size="md" color="gray.800">Revenue Overview</Heading>
                        </HStack>
                        <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="bar"
                            height={350}
                        />
                    </Box>
                </GridItem>
                <GridItem>
                    <Box
                        bg="white"
                        borderRadius="lg"
                        boxShadow="lg"
                        p={6}
                        minH="250px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <HStack spacing={3} mb={4}>
                            <Icon as={MdTrendingUp} boxSize={6} color="#E83E8C" />
                            <Heading size="md" color="gray.800">Percentage Analysis</Heading>
                        </HStack>
                        <Text fontSize="xl" color="#1F80E8" fontWeight="bold">
                            Customer Transactions: {percentageCustomer}%
                        </Text>
                        <Text fontSize="xl" color="#E83E8C" fontWeight="bold">
                            Exclusion Transactions: {percentageExclusion}%
                        </Text>
                    </Box>
                </GridItem>
            </Grid>

            <Divider borderColor="gray.300" />

            <Grid>
                <GridItem>
                    <Box
                        bg="white"
                        borderRadius="lg"
                        boxShadow="lg"
                        p={6}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Heading size="md" color="gray.800" mb={4}>Total Customer Counts</Heading>
                        <Text fontSize="lg" color="gray.600">
                            Total Customer Count: <strong>{totalCustomerCount}</strong>
                        </Text>
                        <Text fontSize="lg" color="gray.600">
                            Total Excluded Customers: <strong>{totalExcludedCustomer}</strong>
                        </Text>
                    </Box>
                </GridItem>
            </Grid>
        </VStack>
    );
};

export default ControlGroup;
