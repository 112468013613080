import React, { useEffect } from 'react'
import { Avatar, Box, HStack, VStack, Text, Image } from '@chakra-ui/react'
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'
import { FaBatteryHalf } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import store from '../../../store/store';
import { MdNetworkWifi3Bar } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs"
import whatsappBG from ".././../../images/whatsappWallpaper.jpg"
import * as _ from 'lodash'
function MobilePreview({ message, channel, buttons, attachment, sample_body }) {
    
    const white_label = store.getState()?.auth?.white_label
    const org = JSON.parse(localStorage.getItem('selectedOrg'))
        
        try {
            buttons = JSON.parse(buttons)
        } catch (e) { }
    
    return (
        <>
            <Box width={'fit-content'} >


                <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] z-[99] ">
                    <div class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                    <div class="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                    <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">

                        <Box bg={'#000'} height={'100%'} bgImage={channel == 'whatsapp' ? whatsappBG : ""} >
                            <>
                                <VStack p={0} alignItems={'baseline'} ml={'auto'} height={'80%%'}  >
                                    {channel == 'sms' &&
                                        <>
                                            <VStack bg={'#1e1e1e'} height={'15%'} ml={0} position={'absolute'} width={'100%'} top={0}  >
                                                <HStack w={'100%'}  alignItems={'start'} mt={'10%'} >
                                                    
                                                    <IoIosArrowBack style={{ alignSelf: 'center', height: '2em', width: '2em' }} fill='#fff' />
                                                    <HStack justifyContent={'center'} ml={'calc( 33% - 2em)'} >
                                                    <VStack gap={"1px"} >
                                                        <Avatar size={'sm'} />
                                                        <Text color={'#fff'} > {white_label?.entityName}</Text>
                                                    </VStack>
                                                    </HStack>
                                                </HStack>

                                                <HStack justifyContent={'space-between'} px={'8%'} w={'100%'} borderTopLeftRadius={'24px'} borderTopRightRadius={'24px'} position="absolute"  top={0}>
                                                    <Box fontSize={'0.7vw'} color={'#fff'} display="inline-block">{new Date().getHours()} : {new Date().getMinutes()} </Box>
                                                    <HStack>
                                                        <MdNetworkWifi3Bar fill='#fff' />
                                                        <FaBatteryHalf fill='#fff' />
                                                    </HStack>
                                                </HStack>
                                            </VStack>
                                        </>
                                    }
                                    {channel == 'whatsapp' &&
                                        <>
                                            <VStack bg={'#02796B'} height={'15%'} p={'15px'} ml={'auto'} w={'100%'}>
                                                <HStack w={'100%'} gap={'3%'} alignItems={'start'} mt={'10%'} >
                                                    <IoIosArrowBack style={{ alignSelf: 'center', height: '2em', width: '2em' }} fill='#fff' />
                                                    <HStack justifyContent={'space-between'} w={'100%'} px={'0.5vw'}>
                                                        <HStack>
                                                            <Avatar size={'sm'} src={org.orgIcon} />
                                                            <Text color={'#fff'} > {org.name} </Text>
                                                        </HStack>
                                                        <HStack>
                                                            <IoCall fill='#fff' />
                                                            <BsThreeDotsVertical fill='#fff' />
                                                        </HStack>
                                                    </HStack>
                                                </HStack>

                                                <HStack justifyContent={'space-between'} bg={'#005048'} borderTopLeftRadius={'24px'}  borderTopRightRadius={'24px'} px={'8%'} w={'100%'} position="absolute" zIndex={1} top={0}  >
                                                    <Box fontSize={'0.7vw'} color={'#fff'}  display="inline-block">{new Date().getHours()} : {new Date().getMinutes()} </Box>
                                                    <HStack>
                                                        <MdNetworkWifi3Bar fill='#fff' />
                                                        <FaBatteryHalf fill='#fff' />
                                                    </HStack>
                                                </HStack>
                                            </VStack>
                                        </>
                                    }

                                    <Box float="left"
                                        maxW="77%"
                                        w="auto"
                                        h="auto"
                                        display="block"
                                        bg={channel == 'whatsapp' ? '#fff' : "#1e1e1e"}
                                        color={channel == 'whatsapp' ? '#000' : '#fff'}
                                        borderRadius="16px"
                                        mt={channel == 'sms' && '39%'}
                                        p="10px 15px"
                                        ml={'10px'}
                                        fontSize={'0.6vw'}
                                        // m="0px 0 3px 12px"
                                        boxShadow="0 1px 0.5px rgba(0, 0, 0, 0.13)"
                                    >
                                        {
                                            !!attachment &&
                                            <Image src={attachment} />
                                        }
                                        {message || sample_body}
                                    </Box>

                                    {
                                        !!buttons && buttons.length > 0 &&
                                        <HStack
                                            maxW="77%"
                                            gap={'4px'}
                                            w={'100%'}
                                            ml={'5px'}
                                        >
                                            {
                                                !!buttons && buttons.length > 0 && buttons?.map((btn) => (
                                                    <Box textAlign={'center'} fontSize={'0.6vw'} boxShadow="0 1px 0.5px rgba(0, 0, 0, 0.13)" width={'100%'} borderRadius={'8px'} bg={'#fff'} p={'2px 15px'} color={'#58A2F6'} >{btn?.button_name}</Box>
                                                ))
                                            }
                                        </HStack>
                                    }
                                </VStack>
                            </>

                        </Box>

                    </div>
                </div>

            </Box>
        </>
    )
}

export default MobilePreview
