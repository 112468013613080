import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getTokenFromLoacalStorage, isTokenExpired } from "../../_helpers/helpers";
import _ from "lodash";
import { extractSelectedOrgFromUserData, extractOrgListFromUserData } from "../../Services/AuthService";
export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: '',
        user_data: {},
        isAuthenticated: false,
        tokenExpiry: moment(),
        selectedOrg: {},
        isLoading: true,
        white_label:{},
        isSuperAdmin:false
    },
    reducers: {
        LOGIN_CONFIRMED: (state, action) => {
            state.token = action.payload.token;
            state.isAuthenticated = !!action.payload.token;
            state.user_data = action.payload.user_data;
            state.tokenExpiry = moment(action.payload.exp);
            state.isLoading = false;
            state.selectedOrg = extractSelectedOrgFromUserData(state.user_data);
            state.isSuperAdmin = action.payload.isSuperAdmin; 
        },
        CHECK_AUTO_LOGIN: (state, action) => {
            
            const {user_data, jwt, exp} = getTokenFromLoacalStorage();
            if(!!jwt && !_.isEmpty(user_data) && !!isTokenExpired(exp)){
                state.token = jwt;
                state.isAuthenticated = !!jwt;
                state.user_data = user_data;
                state.tokenExpiry = moment(exp);
                state.selectedOrg = extractSelectedOrgFromUserData(state.user_data);
              
            } else {
                state.isAuthenticated = false;
                localStorage.removeItem("token");
            }
            state.isLoading = false
        },
        LOGOUT: (state) => {
            state.token = '';
            state.user_data = {};
            state.isAuthenticated = false;
            state.tokenExpiry = moment();
            state.isLoading = false;
            localStorage.removeItem("token")
            localStorage.removeItem("selectedOrg")
            state.selectedOrg = {}
        },
        SET_ORG: (state, action) => {
            state.selectedOrg = action.payload.data
        },
        WHITE_LABEL:(state, action) => {
            state.white_label = action.payload.data;
            sessionStorage.setItem('white_label', JSON.stringify(action.payload.data));
        },

    }
})

export const {LOGIN_CONFIRMED, CHECK_AUTO_LOGIN, LOGOUT, SET_ORG,WHITE_LABEL} = authSlice.actions;