export const getEventsData = () => {
    return {
        data: {
            "join": 738479327,
            "view": 82392173,
            "categoryview": 72837,
            "httppushallowed": 0,
            "productview": 372832332,
            "addtocart": 142838721,
            "checkoutstarted": 67681322,
            "search": 3526536523,
            "mapemail": 378273872,
            "mapphone": 99732737,
            "order": 8327872,
            "buy": 29387430,
            "smsclicked": 0,
            "exitintent": 98,
            "exitintentcampaignuuid": 2389238
        }

    }
}
export const getRevenueData = () => {
    return {
        data: {
            "week 4": 3728373,
            "week 3": 9820122,
            "week 2": 2873873,
            "week 1": 3728832
        }
    }
}

export const topPerformingCampaign = () => {
    return {
        data: {
            "best_performing_campaigns": {
                "DIWALI-SALE-2024" : 2738723,
                "EXTRA-20% OFF" : 2389283,
                "BUY-2 GET 1 FREE" : 2389283
            },
            "revenue_by_broadcast": 389283,
            "revenue_by_automation": 239823
        }

    }
}