import { Box, FormLabel, HStack, Text, useToast, VStack } from '@chakra-ui/react'
import React from 'react'
import CampaignDraftDrawer from '../Campaigns/CampaignDraftDrawer'
import { useState, useEffect } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import store from '../../store/store'
import Select from '../../components/themeComponents/react-chakra-select'
import { prepareOrgList, prepareTargetedAudienceData } from '../../_helpers/data_helper'
import { useSteps } from '@chakra-ui/react'
import { targetAudienceSteps } from '../../_helpers/data_helper'
import CampaignStepper from '../Campaigns/components/CampaignStepper'
import OrgAndConfig from './OrgAndConfig'
import { useFormik } from 'formik'
import AudieceSelection from './AudieceSelection'
import * as _ from "lodash";
import { targetGroupCampaign } from '../../_helpers/formValues'
import TemplateSelectionTG from './TemplateSelectionTG'
import ScheduleCampaign from './ScheduleCampaign'
import { useNavigate } from 'react-router-dom'
import { draftCampaign, draftCampaignAllbanks, publishCampaign, publishCampaignAllBanks } from '../../Services/api'
import OrgAndConfigTG from './OrgAndConfigTG'

import Swal from 'sweetalert2';
// import AudieceSelection from './AudieceSelection'
export default function TargetGroup() {
  const [selectedOrgList, setSelectedOrgList] = useState([]);
  const [isOpen, setIsOpen] = useState(false)
  const [targetGroupData, setTargetGroupData] = useState({})
  const [campaignName, setCampaignName] = useState("")
  const [scheduleData, setScheduleData] = useState({});
  const [selectedGatewayUuid, setSelectedGatewayUuid] = useState({})
  const [templatesData, setTemplatesData] = useState({});
  const [reachForOrgs, setReachForOrgs] = useState({})
  const initializeScheduleData = (token) => {
    setScheduleData(prev => ({
      ...prev,
      [token]: {
        scheduleStatus: 'NOW',
        scheduleAt: new Date(),
        repeatAfterDays: 1,
        repeatStopDateTime: new Date()
      }
    }));
  };



  const formik = useFormik({
    initialValues: targetGroupCampaign,
    enableReinitialize: true,
    onSubmit: () => { }
  })

  const navigate = useNavigate()
  const toast = useToast()
  const { activeStep, setActiveStep } = useSteps({
    index: 0, count: targetAudienceSteps.length
  })


  const saveAndPublishCampaign = async (values, action) => {
    let campaignData = values;
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to save the campaign!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const draftResponse = await draftCampaignAllbanks("", campaignData);
        if (draftResponse.data) {
          let publishResponse = await publishCampaignAllBanks(draftResponse.data, campaignData);
          if (!!publishResponse) {
            toast({
              title: `Campaign published successfully.`,
              status: 'success',
              duration: 3000,
              isClosable: true
            });
            navigate("/all-banks/list");
          }
        }
      }
    });
  };

  useEffect(() => {

    if (Array.isArray(formik.values.orgList) && formik.values.orgList.length > 0) {
      const obj = {};
      formik.values.orgList.forEach((org) => {
        obj[org] = { csv: [], segment: [] };
      });
      setTargetGroupData(obj);
    }
    formik?.values?.orgList.forEach((org) => {
      initializeScheduleData(org)
    })
    console.log(scheduleData)
  }, [formik.values.orgList]);


  let prepareFinalData = () => {
    let retVal = {};

    // Get the first key's data from scheduleData
    let firstKeyScheduleData = scheduleData[Object.keys(scheduleData)[0]];

    formik.values.orgList.forEach((org) => {
        retVal[org] = {
            campaignName: formik.values.campaignName,
            targetedAudience: prepareTargetedAudienceData(targetGroupData[org]),
            orgToken: org,
            selectedChannel: formik.values.selectedChannel,
            selectedSmtpType: "promotional",
            selectedTempName:
                formik.values.selectedChannel == "email"
                    ? templatesData[org]?.body?.label || ""
                    : templatesData[org]?.label || "",
            addCountryCode: true,
            broadcastType: "ALL_BANKS",
            selectedTempHeader: templatesData[org]?.header?.uuid || "",
            selectedTempFooter: templatesData[org]?.footer?.uuid || "",
            selectedTemp:
                formik.values.selectedChannel == "email"
                    ? templatesData[org]?.body?.uuid || ""
                    : templatesData[org]?.uuid || "",
            broadcastBatchWise: "true",
            broadcast: "ALL_BANKS",
            addToContactList: true,
            message: "",
            isUnicode: false,
            sentSmsCount: 1,
            coupon: "",
            mediaUrl: "",
            approvedTemplateId: "",
            selectedGateway: selectedGatewayUuid[org] || "",
            subjectLine : formik?.values?.subjectLine
        };

        
        retVal[org] = { ...retVal[org], ...firstKeyScheduleData };
    });

    console.log(Object.values(retVal));
    return Object.values(retVal);
  };

  useEffect(() => {

    // console.log("targetGrpDtaa", targetGroupData);
    // console.log("templatesData", templatesData)
    // console.log("scheduleData", scheduleData)
    console.log(reachForOrgs)

  }, [targetGroupData, templatesData, scheduleData, reachForOrgs]);


  return (
    <Box marginLeft={'1rem'} overflowY={'scroll'} padding={'2rem'} borderRadius={'1vw'} h={"100%"} bg={'#EEEEEE'} alignItems={'baseline'} border={'1px solid #bebcbc'} >
      <Box  >
        <CampaignStepper steps={targetAudienceSteps} activeStep={activeStep}></CampaignStepper>
        <Box mt={'2rem'} >
          {
            activeStep === 0 &&
            <OrgAndConfig selectedOrgList={selectedOrgList} setSelectedOrgList={setSelectedOrgList} formik={formik} />
            // <OrgAndConfigTG/>
          }
        </Box>
        {
          activeStep === 1 &&
          <AudieceSelection reachForOrgs={reachForOrgs} setReachForOrgs={setReachForOrgs} setTargetGroupData={setTargetGroupData} targetGroupData={targetGroupData} formik={formik} />
        }
        {
          activeStep === 2 &&
          <TemplateSelectionTG selectedGatewayUuid = {selectedGatewayUuid} setSelectedGatewayUuid={setSelectedGatewayUuid} setTemplatesData={setTemplatesData} templatesData={templatesData} formik={formik} />
        }
        {
          activeStep === 3 && <>
            {
              formik.values.orgList.map((org,i) => (
                  i == 0 &&  
                <ScheduleCampaign scheduleData={scheduleData} setScheduleData={setScheduleData} initializeScheduleData={initializeScheduleData} orgToken={org} setTargetGroupData={setTargetGroupData} targetGroupData={targetGroupData} formik={formik} />
              ))
            }
          </>
        }
        <HStack pos={"fixed"} bottom={'2rem'} right={'2rem'} >

          {activeStep > 0 && <PrimaryButton label={'Back'} onClick={() => { setActiveStep(activeStep - 1) }} />}
          <PrimaryButton
            isDisabled={
              (activeStep === 0 &&
                (!formik.values.selectedChannel ||
                  !formik.values.campaignName ||
                  _.isEmpty(formik.values.orgList))) ||
              (activeStep === 1 && Object.values(reachForOrgs).some(orgReach => !orgReach)) ||
              (activeStep === 2 && formik.values.selectedChannel == 'email' && (_.isEmpty(templatesData) || Object.values(templatesData).some(template => {
                return !template?.body?.body
              })))
            }
            label={
              activeStep === 3
                ? 'Publish'
                : 'Save & Continue' 
            }
            onClick={() => {
              if (activeStep === 3) {
                saveAndPublishCampaign(prepareFinalData(), {});
              } else {
                setActiveStep(activeStep + 1);
              }
            }}
          />
        </HStack>
      </Box>
    </Box>

  )
}
