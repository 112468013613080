import React, { useEffect, useRef, useState } from 'react'
import { Box, Text, Flex, FormControl, FormLabel, AspectRatio, Image, FormHelperText, Icon, useDisclosure, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, VStack } from '@chakra-ui/react'
import Select from '../../../components/themeComponents/react-chakra-select'
import { DeviceFrameset } from 'react-device-frameset'
import { useQuery } from 'react-query'
import { fetchEmailChannels, fetchTemplate, getSmsGatways, saveEmailChannels } from '../../../Services/api'
import { prepareGatewayList, prepareTemplateList, selectProvidersValue, smtpTypes } from '../../../_helpers/data_helper'
import { BsBox2Fill } from 'react-icons/bs'
import * as htmltoImage from "html-to-image"
import html2canvas from 'html2canvas'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import TemplatePreviewModal from './TemplatePreviewModal'
import _ from "lodash";
import MobilePreview from './MobilePreview'

function TemplateSelection({ selectedChannel, orgToken, formik , targetRatios, setTargetRatios}) {

    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isPerviewGenerating, setIsPreviewGenerating] = useState(false)
    const [template, setTemplate] = useState({})
    const tempBodyRef = useRef(null)
    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            let res = await fetchTemplate(selectedChannel || formik?.values?.selectedChannel, orgToken, selectedGatewayUuid);

            formik.setFieldValue("selectedTemp", prepareTemplateList(selectedChannel, res?.data)[0].uuid)
            formik.setFieldValue("selectedTemp1", prepareTemplateList(selectedChannel, res?.data)[0].uuid)
            // formik.setFieldValue("selectedTempName",res?.data[0]?.name)

            return prepareTemplateList(selectedChannel, res?.data)
        }
    })

    const handleTargetRatioChange = (temp, value) => {
        if (temp > 5) {
            console.error("Cannot adjust more than 6 templates");
            return;
        }


        const clampedValue = Math.min(Math.floor(value), 100);

        setTargetRatios((prevRatios) => {
            const newRatios = { ...prevRatios };
            const previousValue = newRatios[temp];


            newRatios[temp] = clampedValue;

            if (temp < 5) {

                if (clampedValue < previousValue) {
                    setTemplates((prevTemplates) =>
                        prevTemplates.length <= temp ? [...prevTemplates, ""] : prevTemplates
                    );
                }


                if (!newRatios.hasOwnProperty(temp + 1)) {
                    newRatios[temp + 1] = 0;
                }
            }


            let remainingRatio = 100 - clampedValue;

            // formik.setFieldValue(`selectedTemp${temp +2}`,formik.values.selectedTemp)
            const otherIndices = Object.keys(newRatios)
                .map(Number)
                .filter((index) => index !== temp);

            const totalOtherValues = otherIndices.reduce((acc, index) => acc + newRatios[index], 0);


            otherIndices.forEach((index) => {
                newRatios[index] = totalOtherValues > 0
                    ? Math.floor((newRatios[index] / totalOtherValues) * remainingRatio)
                    : Math.floor(remainingRatio / otherIndices.length);
            });


            const adjustedSum = Object.values(newRatios).reduce((acc, val) => acc + val, 0);
            const adjustment = 100 - adjustedSum;
            newRatios[otherIndices[otherIndices.length - 1]] += adjustment;


            Object.keys(newRatios).forEach((ratioKey, i) => {
                formik.setFieldValue(`targetedAudience.includes[0].targetRatio${i + 1}`, newRatios[ratioKey]);
            });

            return newRatios;
        });


        if (targetRatios[0] < 100) {
            formik.setFieldValue("campaignType", "multiple_schedule_v2");
            formik.setFieldValue("broadcastType", "multiple_schedule_v2");
            formik.setFieldValue("broadcast", "multiple_schedule_v2");
        } else {
            formik.setFieldValue("campaignType", "BROADCAST_V2");
            formik.setFieldValue("broadcastType", "BROADCAST_V2");
            formik.setFieldValue("broadcast", "BROADCAST_V2");
        }
    };

    const [templates, setTemplates] = useState([""])
    // const [template, setTemplate] = useState({
    //     "0": {},
    //     "1": {},
    //     "2": {},
    //     "3": {},
    //     "4": {},
    //     "5": {},
    // });


    // console.log(templateQuery,"templateQuery")
    async function generateEmailPreview() {
        setIsPreviewGenerating(true);
        try {
            const tempBody = tempBodyRef.current;
            const canvas = await htmltoImage.toPng(tempBody);
            // const dataUrl = canvas.toDataURL('image/png');
            formik.setFieldValue('tempImg', canvas)
            setIsPreviewGenerating(false);
        } catch (error) {
            console.log('Error in generating preview', error)
        }
    }
    const [selectedGatewayUuid, setSelectedGatewayUuid] = useState("");

    const emailProvidersQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let emailProviders = await fetchEmailChannels(orgToken);
            console.log(emailProviders)
            return emailProviders?.data?.data;
        },
        enabled: true
    });
    const gatewayQuery = useQuery(['gatways'], {
        queryFn: async () => {
            if(selectedChannel=='sms'){
                let gatewayData = await getSmsGatways();
                console.log(gatewayData)
                return gatewayData.data;
            }

        },
        enabled: true
    });
    const selectTempData = (e, i) => {
        console.log('on temp select', e)
        if (Object.keys(targetRatios).length > 1) {
            formik.setFieldValue(`selectedTemp${i + 1}`, e.uuid);
            formik.setFieldValue(`selectedTempName${i + 1}`, e.label);
            if (selectedChannel === 'email') {
                formik.setFieldValue(`tempBody${i + 1}`, e.body)
                // generateEmailPreview(e.body)
            }
        } else {
            formik.setFieldValue("selectedTemp", e.uuid);
            formik.setFieldValue("message", e.message);
            formik.setFieldValue("attachment", e.attachment);
            formik.setFieldValue("buttons", e.buttons);
            formik.setFieldValue("selectedTempName", e.label);
            if (selectedChannel === 'email') {
                formik.setFieldValue('tempBody', e.body)
                // generateEmailPreview(e.body)
            }
        }

        if (selectedChannel === 'whatsapp') {
            selectTemplate(e, i)
        }
        // formik.setFieldValue("tempBody", e.body)
    }
    const selectTemplate = (e, i) => {
        if (selectedChannel == 'whatsapp') {
            setTemplate(e?.dt)
        } else {
            setTemplate(templateQuery.data.filter(template => template.uuid == e.uuid))
        }
    }
    useEffect(()=>{
        gatewayQuery.refetch()
    },[])
    return (
        <>
            {
                (
                    <>

                        <Box w={'100%'} m={'0 auto'} mt={5} p={3} id='screenshot' bg={'white'} height={"550px"} overflowY={'scroll'} >
                            <Text color={'heading'} as={'b'}>Template Information </Text>
                            {
                        <FormControl>
                            {

                                selectedChannel == 'sms' &&
                                <><FormLabel fontWeight={'600'} color={'inputLabel'}>Select Provider  </FormLabel>
                                    {!!gatewayQuery?.data && gatewayQuery?.data && <Select onChange={(e) => {
                                        formik.setFieldValue("selectedGateway", e.value)
                                        setSelectedGatewayUuid(e.value)
                                        templateQuery.refetch()
                                    }}
                                        options={prepareGatewayList(gatewayQuery?.data?.gatewayList)} />}
                                </>
                            }

                        </FormControl>
                    }
                            <VStack gap={12} mt={4}>

                                {
                                   selectedChannel=='sms' && !!selectedGatewayUuid && Object.keys(targetRatios).map((template, index) => (

                                        <Box p={3} bg={'white'} w={'60%'} >
                                            <FormControl>
                                                <FormLabel color={'inputLabel'}>Template </FormLabel>
                                                <Select
                                                    value={targetRatios[0] == 100 ? _.filter(templateQuery?.data, { uuid: formik.values[`selectedTemp`] }) : _.filter(templateQuery?.data, { uuid: formik.values[`selectedTemp${index + 1}`] || formik.values[`selectedTemp`] })}
                                                    options={templateQuery?.data} onChange={(e) => selectTempData(e, index)}
                                                ></Select>
                                                {
                                                    !!formik.values.selectedTemp && <Box float={'right'} title='Preview' onClick={onOpen} cursor={'pointer'}>
                                                    </Box>
                                                }

                                            </FormControl>
                                            {
                                                (formik.values.campaignType.toLowerCase() == "broadcast" || formik.values.campaignType == "multiple_schedule_v2" || formik.values.campaignType == "BROADCAST_V2") &&
                                                <>
                                                    <FormLabel mt={2} fontWeight={'600'} color={'inputLabel'}>Target Ratio </FormLabel>
                                                    <Slider
                                                        value={targetRatios[index]}
                                                        min={0}
                                                        max={100}
                                                        onChange={(value) => handleTargetRatioChange(index, value)}
                                                    >
                                                        <SliderTrack>
                                                            <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb bg='black' />
                                                        <SliderMark value={targetRatios[index]} mt='2' ml='-2.5' color='black'>
                                                            {targetRatios[index]}%
                                                        </SliderMark>
                                                    </Slider>
                                                </>
                                            }
                                            
                                        </Box>
                                    ))

                                }
                                {
                                   selectedChannel!=='sms' && Object.keys(targetRatios).map((template, index) => (

                                        <Box p={3} bg={'white'} w={'60%'} >
                                            <FormControl>
                                                <FormLabel color={'inputLabel'}>Template </FormLabel>
                                                <Select
                                                    value={targetRatios[0] == 100 ? _.filter(templateQuery?.data, { uuid: formik.values[`selectedTemp`] }) : _.filter(templateQuery?.data, { uuid: formik.values[`selectedTemp${index + 1}`] || formik.values[`selectedTemp`] })}
                                                    options={templateQuery?.data} onChange={(e) => selectTempData(e, index)}
                                                ></Select>
                                                {
                                                    !!formik.values.selectedTemp && <Box float={'right'} title='Preview' onClick={onOpen} cursor={'pointer'}>
                                                    </Box>
                                                }

                                            </FormControl>
                                            {
                                                (formik.values.campaignType == "Broadcast" || formik.values.campaignType == "multiple_schedule_v2" || formik.values.campaignType == "BROADCAST_V2") &&
                                                <>
                                                    <FormLabel mt={2} fontWeight={'600'} color={'inputLabel'}>Target Ratio </FormLabel>
                                                    <Slider
                                                        value={targetRatios[index]}
                                                        min={0}
                                                        max={100}
                                                        onChange={(value) => handleTargetRatioChange(index, value)}
                                                    >
                                                        <SliderTrack>
                                                            <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb bg='black' />
                                                        <SliderMark value={targetRatios[index]} mt='2' ml='-2.5' color='black'>
                                                            {targetRatios[index]}%
                                                        </SliderMark>
                                                    </Slider>
                                                </>
                                            }
                                            
                                        </Box>
                                    ))

                                }
                                

                                {/* <Box ratio={3 / 4} w={'40vw'} maxW={'530px'} bg="white" overflow={'scroll'} maxH={'450px'} >
                    <Box transform={'scale(0.8)'} dangerouslySetInnerHTML={{ __html: formik.values.tempBody }}></Box>
                </Box> */}
                                {/* {
                    selectedChannel == 'whatsapp' ? <MobilePreview channel={'whatsapp'} message={formik.values.message} /> :
                        <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
                } */}

                                {/* <Box>
                                    {
                                        selectedChannel == 'whatsapp' ? <MobilePreview channel={'whatsapp'} message={template.message} attachment={template.attachment} buttons={template.buttons} /> :
                                            <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
                                    }
                                </Box> */}

                            </VStack>
                        </Box>
                    </>
                )
            }

        </>
    )
}

export default TemplateSelection
