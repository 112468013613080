import logo from './logo.svg';
import { Fragment, useEffect } from 'react';
import './App.css';
import { Routes, RouterProvider ,useSearchParams, useNavigate} from 'react-router-dom';


import { routes } from './routes/routes.js';
import { useDispatch } from 'react-redux';
import { CHECK_AUTO_LOGIN } from './store/Reducers/AuthReducer.js';
import { getSelectedOrgFromLocalSorage, loginConfirm, setSelectedOrganization } from './Services/AuthService.js';
import { WHITE_LABEL } from './store/Reducers/AuthReducer.js';
import store from './store/store.js';
import { getWLData } from './Services/WhiteLabelService.js';
import "./i18n"
import { setTokenInLocalStorage } from './_helpers/helpers.js';
import { useToast } from '@chakra-ui/react';
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    
    dispatch(CHECK_AUTO_LOGIN());
    let org = getSelectedOrgFromLocalSorage();
    setSelectedOrganization(dispatch, org)
    // console.log(whiteLabel);
    
    if (!!sessionStorage.getItem('whiteLabel')) {
      dispatch(WHITE_LABEL({ data: JSON.parse(sessionStorage.getItem('whiteLabel')) }));
      const whiteLabel  = store.getState().auth.white_label
      
      document.title = whiteLabel.data.entityName
      let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
          link.href =  whiteLabel.data.favicon;
        }
    } else {
      getWLData().then(res => {
        sessionStorage.setItem("whiteLabel", JSON.stringify(res.data));
        document.title = res.data.data.entityName
        dispatch(WHITE_LABEL({ data: res.data.data }));
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = res.data.data.favicon;
      }).catch(err => {
        console.log(err);
      })
    }

  }, [])
  return (
    <Fragment>
      <RouterProvider router={routes}></RouterProvider>
    </Fragment>
  );
}

export default App;
