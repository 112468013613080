import { Box, FormLabel, Input, Select, Text, Tag, TagLabel, TagCloseButton, Checkbox, InputRightAddon, InputGroup, Flex, VStack, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Tooltip } from '@chakra-ui/react'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import FlowEditService from '../helpers/FlowEditService'
import { getInitialPropsQueries, prepareEventNameJson } from '../helpers/FlowQueries'
import { useQuery } from 'react-query'
import _ from "lodash"
import PrimaryButton from '../../../components/themeComponents/PrimaryButton'
import { WithContext as ReactTags } from 'react-tag-input'
import { fetchEventNameJson, getSegmentProps } from '../../../Services/api'
import ShowToastr from '../../../components/themeComponents/ShowToastr'
import { DebounceInput } from "react-debounce-input"
import { eventProperty, automationOptions } from '../../../_helpers/data_helper'
import { sendSegmentEvents } from '../../../Services/api'
import { json } from 'react-router-dom'
export default function InspectorForInitial({ segmentEvents, setSegmentEvents, formData, updateFormData, formAcionProps, eventQueries, validateFormData, updateFormActionProps, setShowDateTimePicker }) {
  const formik = useFormik({
    initialValues: {
      addEventCondition: false,
      initialCriteria: FlowEditService.initialCriteria,
      formAcionProps: { ...formAcionProps },
      isFollowedBy: false,
    }
  })
  const [reachTooltip, setReachTooltip] = useState(false)
  const fetchSegmentChangeEvents = async () => {
    try {
      let events;
      const selectedOrg = localStorage.getItem('selectedOrg');
      if (option === "Segment Change Events") {
        const orgData = JSON.parse(selectedOrg);
        const orgId = orgData.token;
        events = await sendSegmentEvents(orgId);
        setSegmentEvents(events.data?.data)

      } else {
        events = await fetchEventNameJson();
        setClickEvents(events.data)
      }

    } catch (error) {
      console.error("Error fetching segment change events:", error);
    }
  }
  // const handleChange = (e, index) => {

  //   setSelectedValue(e.target.value,index);
  //   setIsSelected(true);
  //   selectEvent(e.target.value, index);
  //   setAutomation(e.target.value,index)
  // };
  const handleChange = (e, index) => {
    
    const selectedId = e.target.value;
    setSelectedValue(selectedId);
    // setIsSelected(true);
    selectEvent(selectedId, index);
    setAutomation(selectedId, index);


    const selectedSegment = !!segmentEvents && segmentEvents.find(segment => segment.id === parseInt(selectedId));


    const count = selectedSegment?.aggregates?.count_customers || 0;


    setCustomerCount(count);
    const tempFormData = { ...formData };
    tempFormData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId = e.target.value
    tempFormData['beginsWith'].nextActionCondition.criteria[0].value[0] = e.target.value
    tempFormData['beginsWith'].nextActionCondition.criteria[0].reach = count;
    updateFormData(tempFormData);
  };

  // const handleChange = (e, index) => {
  //   // const targetValue = e.target.value;
  //   console.log(targetValue,"E>>>>EE>E>E>")
  //   setSelectedValue(selectedValue);
  //   setIsSelected(true);
  //   selectEvent(selectedValue, index);
  // };

  const getInitialValueType = (criterionProperty) => {
    if (criterionProperty === 'EventValue' || criterionProperty === 'EventCityName' || criterionProperty === 'EventLanguage') {
      return 'input';
    } else return 'dropdown';
  }
  const addCriterion = (obj, index, criterion) => {
    if (!!obj) {
      let tempFields = [...obj];
      tempFields.splice(tempFields.length, 0, criterion)


      formik.setFieldValue("initialCriteria", tempFields);
      formik.setFieldValue("addEventCondition", true)

    }
  };
  const selectEvent = (value, index) => {
    setSelectedValue(value);
    //fetchSegmentChangeEvents();
    setSelectedSegmentEventId(value.id)
    const tempFormData = { ...formData };
    tempFormData.beginsWith.nextActionCondition.criteria[0].value[0] = value;
    updateFormData(tempFormData);
    setTimeout(() => {
      validateFormData('beginsWith')
    }, 200)

  }
  const saveEventCondition = () => {
    const tempFormData = { ...formData };
    tempFormData['beginsWith'].nextActionCondition.criteria.push(formik.values.initialCriteria[1]);
    updateFormData(tempFormData)
    formik.setFieldValue('initialCriteria', FlowEditService.initialCriteria);
    formik.setFieldValue("addEventCondition", false);
    validateFormData("beginsWith")
  }
  const saveFollowedBy = (formikProp, value, prop) => {
    formik.setFieldValue(formikProp, value);
    const tempFormAcionProps = { ...formik.values.formAcionProps };
    tempFormAcionProps.followedBy[prop] = value;
    updateFormActionProps(tempFormAcionProps)
  }
  const saveGoalCondition = (formikProp, value, prop) => {
    formik.setFieldValue(formikProp, value);
    const tempFormActionProps = { ...formik.values.formAcionProps };
    tempFormActionProps.goal[prop] = value;
    updateFormActionProps(tempFormActionProps);
  }
  const [option, setOption] = useState('');


  const [selectedValue, setSelectedValue] = useState('');
  const [automation, setAutomation] = useState('');
  const [selectSegmentEvent, setSelectSegemntEvent] = useState('Select Segment Event')
  const [clickEvents, setClickEvents] = useState([])
  const [selectedSegmentEventId, setSelectedSegmentEventId] = useState(null);
  // const[isSelected,setIsSelected]=useState(false);

  const [customerCount, setCustomerCount] = useState(0);


  useEffect(() => {
    // if (option === "Click Stream Events") {
    fetchSegmentChangeEvents();
    // }
  }, [option]);
  const handleAddOrRemoveCustomer = (id, method) => {
    if (id !== "No segments") {

      setSelectSegemntEvent(`segment_${method}_${formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId}`)
      // console.log(selectSegmentEvent,"SE console")
      formData['beginsWith'].nextActionCondition.criteria[0].value[0] = `segment_${method}_${formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId}`
    }
  }

  useEffect(() => {
    fetchSegmentChangeEvents()
    if(formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId && formData['beginsWith'].nextActionCondition.criteria[0].value[0].includes(("add"))){
      handleAddOrRemoveCustomer(formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId,"add");
    }
    if(formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId && formData['beginsWith'].nextActionCondition.criteria[0].value[0].includes(("remove"))){
      handleAddOrRemoveCustomer(formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId,"remove");
    } 
  }, [])
  // const handleRemoveCustomer = (id) => {
  //   console.log("Removed", id);
  //   setSelectSegemntEvent(`segment_remove_${id}`)
  //   formData['beginsWith'].nextActionCondition.criteria[0].value[0] = selectSegmentEvent
  // }
  return (
    <Fragment>

      <FormikProvider value={formik}>
        {/* {JSON.stringify(formData['beginsWith'].nextActionCondition.criteria[0].value[0])} */}
        <FieldArray name="initialCriteria" render={(arrayHelper) => (
          <Box>
            {
              formik.values.initialCriteria.map((criterion, index) => (
                <Fragment key={JSON.stringify(criterion)}>

                  {
                    (index !== 0) && !!formik.values.addEventCondition && criterion.property === 'EventValue' && (
                      <Select mt={2} name={`initialCriteria[${index}].operator`} value={formik.values.initialCriteria[index].operator} onChange={formik.handleChange} placeholder='Select Operator'>

                        {
                          FlowEditService.eventValueOperators.map(op => (
                            <option key={op.key} value={op.key}>{op.label}</option>
                          ))
                        }
                      </Select>
                    )
                  }
                  {
                    getInitialValueType(criterion.property) === 'dropdown' && (
                      <Fragment>
                        <Text mt={2} color={'inputLabel'}>Event</Text>
                        <Select
                          // placeholder='Click Stream Events'
                          // defaultValue={eventProperty[0].code }
                          value={formData['beginsWith']?.nextActionCondition?.eventType}
                          onChange={(e) => {

                            formik.setFieldValue("beginsWith.nextActionCondition.eventType", e.target.value)
                            formData['beginsWith'].nextActionCondition.eventType = e.target.value
                            setOption(e.target.value);
                            e.target.value == 'Segment Change Events' &&
                              setShowDateTimePicker(true)
                            // selectEvent(e.target.value, index,);
                          }}
                        >
                          {
                            eventProperty && eventProperty.map(ev => (
                              <option value={ev.name} key={ev.code}>{ev.name} </option>
                            ))
                          }
                        </Select>
                        {
                          formData['beginsWith'].nextActionCondition.eventType === 'Segment Change Events' ? (
                            // <Select>
                            <>
                              {(
                                <>
                                  <Select
                                    placeholder={selectSegmentEvent}
                                    // defaultValue={selectSegmentEvent}
                                    value={formData['beginsWith']?.nextActionCondition?.criteria[0]?.value[0]}
                                    mt={4}
                                    onChange={(e) => handleChange(e, index)}
                                  >
                                    {segmentEvents.map((ev) => (
                                      <option value={ev?.id} key={ev?.id}>
                                        {ev?.label || ev?.name}
                                      </option>
                                    ))}
                                  </Select>
                                  <Select
                                    // placeholder={automation}
                                    value={formData['beginsWith'].nextActionCondition.criteria[0]?.automationType || "automation"}
                                    mt={4}
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;
                                      const tempFormData = { ...formData };
                                      tempFormData.beginsWith.nextActionCondition.criteria[0].automationType = selectedValue;
                                      updateFormData(tempFormData);
                                    }}
                                  >
                                    {automationOptions.map((ev) => (
                                      <option value={ev?.id} key={ev?.id}>
                                        {ev?.name}
                                      </option>
                                    ))}
                                  </Select>

                                  <Box>

                                    <Flex mt={4}>

                                      <Text>Customer Count: {customerCount}</Text>
                                    </Flex>
                                  </Box>
                                </>
                              )}
                              {!!formData['beginsWith'].nextActionCondition.criteria[0].value[0] && (
                                <Box>
                                  <Flex key={segmentEvents[index]?.id} mt={2} alignItems="center">
                                    <Text
                                      onClick={() => handleAddOrRemoveCustomer(selectedValue, "add")}
                                      ml={2}
                                      fontSize="sm"
                                      textDecoration="underline"
                                      cursor="pointer"
                                    >
                                      Add Customer
                                      {/* {JSON.stringify(selectedSegmentEventId)} */}
                                    </Text>
                                    <Text
                                      onClick={() => handleAddOrRemoveCustomer(selectedValue, "remove")}
                                      ml={2}
                                      fontSize="sm"
                                      textDecoration="underline"
                                      cursor="pointer"
                                    >
                                      Remove Customer
                                    </Text>
                                  </Flex>
                                </Box>
                              )}
                              <Text mt={2} color={'inputLabel'}>Select Reach Percentage</Text>
                              {JSON.stringify(formData['beginsWith'].nextActionCondition.criteria[0]?.reactPercentage)}
                              <Slider onMouseOver={() => setReachTooltip(true)}
                                onMouseLeave={() => { setReachTooltip(false) }} aria-label='slider-ex-1' value={formData['beginsWith'].nextActionCondition.criteria[0]?.reactPercentage || 0} onChange={(v) => {
                                  // handleReachBiasing(idx, key, num, v);
                                  const tempFormData = { ...formData };
                                  tempFormData.beginsWith.nextActionCondition.criteria[0].reactPercentage = v;
                                  updateFormData(tempFormData)
                                }}

                              >

                                <SliderTrack>
                                  <SliderFilledTrack />
                                  <Tooltip
                                    hasArrow
                                    bg='teal.500'
                                    color='white'
                                    placement='top'
                                    isOpen={reachTooltip}
                                    label={`${formData['beginsWith'].nextActionCondition.criteria[0]?.reactPercentage || 0}%`}
                                  >
                                    <SliderThumb />
                                  </Tooltip>
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </>
                          ) : (
                            // clickEvents &&(
                            <Box>
                              <Select
                                placeholder={"Select Events"}
                                // value={clickEvents}
                                mt={4}
                                onChange={(e) => {

                                  // setClickEvents(e.target.value);
                                  // setIsSelected(true);
                                  selectEvent(e.target.value, index);
                                }}
                              >
                                {clickEvents.map((ev) => (
                                  <option value={ev.id} key={ev.id}>
                                    {ev.label}
                                  </option>
                                ))}
                              </Select>

                            </Box>
                            // ) 
                          )
                        }

                        {/* <Select placeholder='Select Event' value={formData.beginsWith.nextActionCondition.criteria[index].value[0]}
                          onChange={(e) => selectEvent(e.target.value, index)}>

                          {
                            !!eventQueries[criterion.property]?.data?.length && eventQueries[criterion.property].data.map(ev => (
                              <option value={ev.code} key={ev.code}>{ev.name}</option>
                            ))
                          }
                        </Select> */}
                        {
                          !!eventQueries[criterion.property] && !eventQueries[criterion.property].isFetched && !eventQueries[criterion.property]?.data?.length && (() => {
                            console.log("calling again")
                            eventQueries[criterion.property].refetch();
                          })()
                        }
                      </Fragment>
                    )
                  }
                  {
                    !!formik.values.addEventCondition && criterion.property === 'EventValue' && getInitialValueType(criterion.property) !== 'dropdown' &&
                    !_.isEqual(criterion.operator, 'ContainsIn') && !_.isEqual(criterion.operator, 'DoesNotContainsIn') && (
                      <Input placeholder='Enter Value' mt={2} name={`initialCriteria[${index}].value[0]`} value={formik.values.initialCriteria[index].value[0]} onChange={formik.handleChange}></Input>
                    )
                  }

                  {
                    (_.isEqual(criterion.operator, 'ContainsIn') || _.isEqual(criterion.operator, 'DoesNotContainsIn')) && (
                      <Text>Tag Input</Text>
                    )
                  }




                </Fragment>
              ))
            }
          </Box>
        )} />
        {
          formData.beginsWith.nextActionCondition.criteria.length > 1 && (
            <Fragment>
              <FormLabel color={'inputLabel'}>Event Value Matches</FormLabel>
              {
                formData.beginsWith.nextActionCondition.criteria.slice(1).map(tg => {
                  if (!!tg.value[0] && !!tg.operator) {
                    return <Tag size={'sm'} key={JSON.stringify(tg)} variant={'solid'} colorScheme='teal' mr={1}>
                      <TagLabel>{tg.operator} - {tg.value[0]}</TagLabel>
                      {
                        formData.beginsWith.nextActionCondition.criteria.length > 1 && <TagCloseButton></TagCloseButton>
                      }

                    </Tag>
                  }

                })
              }
            </Fragment>
          )
        }
        {
          !formik.values.addEventCondition && formData.beginsWith.nextActionCondition.criteria.length <= 6 && (
            <Box>
              <PrimaryButton size={'sm'} label={'Add Event Condition'} mt={2} onClick={() => addCriterion(formik.values.initialCriteria, null, { 'property': 'EventValue', 'operator': '', 'value': [] })}></PrimaryButton>
            </Box>
          )
        }
        {
          !!formik.values.addEventCondition && (
            <Text as={'b'} float={'right'} onClick={saveEventCondition} cursor={'pointer'}>Save</Text>
          )
        }
        <Field name={'isFollowedBy'} >
          {({ field }) => (
            <Checkbox mt={5} name={'formAcionProps.followedBy.isFollowedBy'} onChange={(e) => saveFollowedBy("formAcionProps.followedBy.isFollowedBy", e.target.checked, 'isFollowedBy')}
              isChecked={formik.values.formAcionProps.followedBy.isFollowedBy}>
              Following Actions
            </Checkbox>
          )}
        </Field>
        {
          !!formik.values.formAcionProps.followedBy.isFollowedBy && <Fragment>
            <FormLabel color={'inputLabel'} mt={2}>Select Operator</FormLabel>
            <Select name={`formActionProps.followedBy.operator`} value={formik.values.formAcionProps.followedBy.operator}
              onChange={(e) => saveFollowedBy('formActionProps.followedBy.operator', e.target.value, 'operator')} placeholder='Select Operator'>

              <option value={'NotEquals'}>
                {'Not followed By'}
              </option>
            </Select>
            <FormLabel color={'inputLabel'} mt={2}>
              Select Event
            </FormLabel>
            <Select mt={2} name={`formActionProps.followedBy.eventName`} value={formik.values.formAcionProps.followedBy.eventName}
              onChange={(e) => saveFollowedBy('formActionProps.followedBy.eventName', e.target.value, 'eventName')} placeholder='Select Event'>
              {
                !!eventQueries['EventName']?.data?.length && eventQueries['EventName'].data.map(ev => (
                  <option value={ev.code} key={ev.code}>{ev.name}</option>
                ))
              }
            </Select>
            <FormLabel color={'inputLabel'} mt={2}>
              Duration
            </FormLabel>
            <InputGroup>
              <DebounceInput element={Input}
                debounceTimeout={500} onChange={(e) => saveFollowedBy("formActionProps.followedBy.expiry", e.target.value, 'expiry')} name='formActionProps.followedBy.expiry' value={formik.values.formAcionProps.followedBy.expiry}>

              </DebounceInput>
              {/* <Input name='formActionProps.followedBy.expiry' value={formik.values.formAcionProps.followedBy.expiry}
                onChange={(e) => formik.setFieldValue('formAcionProps.followedBy.expiry', e.target.value)}></Input> */}
              <InputRightAddon p={0} bg={'transparent'}>
                <Select minW={'150px'} name={`formAcionProps.followedBy.timeUnit`} value={formik.values.formAcionProps.followedBy.timeUnit}
                  onChange={(e) => saveFollowedBy('formAcionProps.followedBy.timeUnit', e.target.value, 'timeUnit')}>
                  {
                    FlowEditService.getFollowedByDurations().map(duration => (
                      <option key={duration.key} value={duration.key}>{duration.label}</option>
                    ))
                  }
                </Select>
              </InputRightAddon>

            </InputGroup>
          </Fragment>
        }
        <Field name={'goal'} >
          {({ field }) => (
            <Checkbox mt={5} w={'100%'} name={'formAcionProps.goal.hasGoal'} onChange={(e) => saveGoalCondition("formAcionProps.goal.hasGoal", e.target.checked, 'hasGoal')} isChecked={formik.values.formAcionProps.goal.hasGoal}>
              Goal Condition
            </Checkbox>
          )}

        </Field>
        {
          !!formik.values.formAcionProps.goal.hasGoal && <Fragment>
            <FormLabel color={'inputLabel'} mt={2}>Select Event</FormLabel>
            <Select minW={'150px'} name={`formAcionProps.goal.eventName`} value={formik.values.formAcionProps.goal.eventName}
              onChange={(e) => saveGoalCondition('formAcionProps.goal.eventName', e.target.value, 'eventName')} placeholder='Select Event'>
              {
                !!eventQueries['EventName']?.data?.length && eventQueries['EventName'].data.map(ev => (
                  <option value={ev.code} key={ev.code}>{ev.name}</option>
                ))
              }
            </Select>
            <FormLabel mt={2} color={'inputLabel'}>Value(s)</FormLabel>
            <ReactTags tags={formik.values.formAcionProps.goal.eventValue} delimiters={[13]} handleDrag={(tag, currPos, newPos) => console.log(tag, currPos, newPos)} placeholder='Add new value'
              handleAddition={(tag) => saveGoalCondition("formAcionProps.goal.eventValue", [...formik.values.formAcionProps.goal.eventValue, tag], "eventValue")} inputFieldPosition="bottom"
              inline={false} classNames={{ tag: 'customTag', tagInputField: 'inputTag', selected: 'tagsWrapper' }}
              handleDelete={(i) => saveGoalCondition("formAcionProps.goal.eventValue", formik.values.formAcionProps.goal.eventValue.filter((tag, index) => index !== i), 'eventValue')}></ReactTags>
          </Fragment>
        }
      </FormikProvider>
    </Fragment>
  )
}
