import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Box, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';

const GupShup = ({ formikProps ,setProviderData }) => {
  const { handleChange, errors, touched } = useFormikContext();

  return (
    <Box p={4} borderWidth={1} borderRadius="lg" boxShadow="lg">
      <FormControl id="hsmAccount" mb={4} isInvalid={errors.hsmAccount && touched.hsmAccount}>
        <FormLabel>HSM Account Id</FormLabel>
        <Field name="hsmAccount" as={Input} placeholder="HSM Account Id<" onChange={(e) => {
          handleChange(e);
          setProviderData((prev) => ({ ...prev, hsmAccount: e.target.value }));
        }} />
        <FormErrorMessage>{errors.hsmAccount}</FormErrorMessage>
      </FormControl>

      <FormControl id="hsmPassword" mb={4} isInvalid={errors.hsmPassword && touched.hsmPassword}>
        <FormLabel>HSM Account Password</FormLabel>
        <Field name="hsmPassword" as={Input} placeholder="HSM Account Password" onChange={(e) => {
          handleChange(e);
          setProviderData((prev) => ({ ...prev, hsmPassword: e.target.value }));
        }} />
        <FormErrorMessage>{errors.hsmPassword}</FormErrorMessage>
      </FormControl>

      <FormControl id="twoWayAccount" mb={4} isInvalid={errors.twoWayAccount && touched.twoWayAccount}>
        <FormLabel>Two Way Account Id</FormLabel>
        <Field name="twoWayAccount" as={Input} placeholder="Two Way Account Id" onChange={(e) => {
          handleChange(e);
          setProviderData((prev) => ({ ...prev, twoWayAccount: e.target.value }));
        }} />
        <FormErrorMessage>{errors.twoWayAccount}</FormErrorMessage>
      </FormControl>

      <FormControl id="twoWayPassword" mb={4} isInvalid={errors.twoWayPassword && touched.twoWayPassword}>
        <FormLabel>Two Way Account Password</FormLabel>
        <Field name="twoWayPassword" as={Input} placeholder="Two Way Account Password" onChange={(e) => {
          handleChange(e);
          setProviderData((prev) => ({ ...prev, twoWayPassword: e.target.value }));
        }} />
        <FormErrorMessage>{errors.twoWayPassword}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default GupShup;
